<template>
  <div class="container mescroll-touch">
    <div class="header">
      <div class="searchContent">
        <div class="searchBox">
          <input class="searchInput" type="text" v-model="searchValue" placeholder="请输入关键字搜索" />
          <img class="closeIcon" @click="clearInput" src="@/assets/images/delete_reseach_icon@2x.png" />
        </div>
        <span class="searchBtn" @click="search">搜索</span>
      </div>
      <div class="header_title">
        <span class="searchHistory" v-show="!searchKey">搜索历史</span>
        <span class="searchHistory" v-show="searchKey">您的关键字“{{searchKey}}”搜索结果：</span>
        <img class="clearIcon" v-show="!searchKey" src="@/assets/images/delete_ss_icon@2x.png" @click="clearSearch" />
      </div>
    </div>
    <div class="content" v-show="!searchKey">
      <div class="historyList">
        <span v-for="(item, index) in HistoryList" :key="index" @click="historySearch(item.SearchKey)">{{item.SearchKey}}</span>
      </div>
      <div class="popularSearch" v-if="PopularList.length > 0">
        <div class="popularTitle">
          <span>热门搜索</span>
          <img src="@/assets/images/hot_comm_icon@2x.png" />
        </div>
        <div class="popularList">
          <span v-for="(item, index) in PopularList" :key="index" @click="popularSearch(item.Name)">{{item.Name}}</span>
        </div>
      </div>
    </div>

    <mescroll-vue v-show="searchKey" ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
      <div id="dataList">
        <waterfall :col="2" :width="itemWidth" :gutterWidth="gutterWidth" :data="list">
          <template>
            <div class="list" v-for="(item, index) in list" :key="index" @click="toContentPage(item.Id, item.Type)">
              <div class="item_img">
                <img class="item_cover" :src="item.Thumbnail" />
                <img class="playIcon" src="@/assets/images/play_comm_icon@2x.png" />
              </div>
              <div class="item_content">
                <span class="item_title">{{item.Title}}</span>
                <p class="item_time">{{item.yyyymmdd}}</p>
                <div class="item_label">
                  <span v-for="(label, labelIndex) in item.TagListBy" :key="labelIndex">{{label.DisplayName}}</span>
                </div>
                <div class="item_nums">
                  <div class="nums">
                    <img src="@/assets/images/browse_comm_icon@2x.png" />
                    <span>{{item.WatchCount}}</span>
                  </div>
                  <div class="nums">
                    <img src="@/assets/images/like_comm_icon@2x.png" />
                    <span>{{item.LikeCount}}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </waterfall>
      </div>
    </mescroll-vue>
  </div>
</template>

<script>
import mixin from '../../common/mixin/mixins'
import { mapState } from 'vuex'
import MescrollVue from 'mescroll.js/mescroll.vue'

export default {
  name: 'Search',
  data() {
    return {
      searchValue: '',
      searchKey: '',
      HistoryList: [],
      PopularList: [],
      list: [],
      PageIndex: 0,
      PageSize: 6,
      mescroll: null, // mescroll实例对象
      mescrollDown:{
        use: false,
      },
      mescrollUp: { // 上拉加载的配置.
        isBounce: false,
			  callback: this.upCallback, // 上拉回调
				page: {
					num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
					size: 6 //每页数据条数,默认10
				},
        noMoreSize: 0,
				htmlNodata: '<p class="upwarp-nodata">-- END --</p>',
        toTop: {
          src: './static/image/top_comm_icon@2x.png' // 回到顶部按钮的图片路径,支持网络图
        },
        empty: {
          // 列表第一页无任何数据时,显示的空提示布局; 需配置warpId才生效;
          warpId: 'dataList', // 父布局的id;
          icon: './static/image/none_comm_png@2x.png', // 图标,支持网络图
          tip: `很抱歉！<br/>似乎找不到任何与您搜索内容匹配的结果`, // 提示
        },
			},
    }
  },
  mixins: [mixin],
  components: {
    MescrollVue
  },
  computed: {
    ...mapState(['userInfo']),
    itemWidth() {
      return (340 * 0.5 * (document.documentElement.clientWidth / 375))
    },
    gutterWidth() {
      return (20 * 0.5 * (document.documentElement.clientWidth / 375))
    }
  },
  watch: {
    searchValue(newval) {
      if (newval == '') {
        this.searchKey = '';
      }
    }
  },
  activated(){
    this.mescroll.setBounce(false);
    this.mescroll.scrollTo(this.mescroll.preScrollY, 0);
  },
  deactivated() {
    this.mescroll.setBounce(true);
  },
  created() {
    this.getHistoryList();
    this.getPopularList();
  },
  methods: {
    // mescroll组件初始化的回调,可获取到mescroll对象
		mescrollInit (mescroll) {
			this.mescroll = mescroll  // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
		},
    // 上拉回调
		upCallback (page) {
      this.getList(page.num, page.size);
		},
    getList(PageIndex, PageSize) {
      this.PageIndex = PageIndex;
      this.PageSize = PageSize;
      setTimeout(() => {
        this.$axios.post('/Api/Api/Web/Article/GetArticleList', {
          KeyWords: this.searchValue,
          ModuleType: 7,
          PageIndex: this.PageIndex,
          PageSize: this.PageSize
        }).then((res) => {
          let arr = res.Data.List;
          // 如果是第一页需手动置空列表
          if (PageIndex === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr);
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            this.mescroll.endSuccess(arr.length)
          })
        }).catch((e) => {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          this.mescroll.endErr()
        })
      }, 200);
    },
    getHistoryList() {
      this.$axios.get('/Api/api/Web/Cus_Search/GetCus_SearchByCustomerId?CustomerID=' + this.userInfo.Id).then(res => {
        // this.HistoryList = res.Data.List;
        this.HistoryList = this.duplicate(res.Data.List, 'SearchKey');
      })
    },
    getPopularList() {
      this.$axios.get('/Api/api/Web/Cus_Search/GetHotSearch').then(res => {
        this.PopularList = res.Data;
      })
    },
    clearInput() {
      this.searchValue = '';
    },
    search() {
      if(this.searchValue) {
        this.BuriedPoint(2, this.searchValue, '', '');
        this.list = [];
        this.mescroll.resetUpScroll() // 刷新列表数据
        this.searchKey = this.searchValue;
        this.addSearchHistory(this.searchValue);
      }
    },
    addSearchHistory(searchValue) {
      this.$axios.post('/Api/api/Web/Cus_Search/AddCus_Search?CustomerId=' + this.userInfo.Id + '&SearchKey=' + searchValue).then(res => {
        this.getHistoryList();
      })
    },
    clearSearch() {
      this.$axios.post('/Api/api/Web/Cus_Search/ClearCus_SearchByCustomerId?CustomerId=' + this.userInfo.Id).then(() => {
        this.getHistoryList();
      })
    },
    historySearch(searchValue) {
      this.searchValue = searchValue;
      this.searchKey = searchValue;
      this.list = [];
      this.mescroll.resetUpScroll() // 刷新列表数据
      this.addSearchHistory(this.searchValue);
      this.BuriedPoint(2, this.searchValue, '', '');
    },
    popularSearch(searchValue) {
      this.searchValue = searchValue;
      this.searchKey = searchValue;
      this.list = [];
      this.mescroll.resetUpScroll() // 刷新列表数据
      this.addSearchHistory(this.searchValue);
      this.BuriedPoint(2, this.searchValue, '', '');
    },
    toContentPage(Id, Type) {
      this.$router.push({
        path: 'contentPage',
        query: {
          id: Id,
          type: Type
        }
      });
    },
    // duplicate(data){
    //   let map = new Map();
    //   for (let item of data) {
    //     if (!map.has(item.name)) {
    //       map.set(item.name, item);
    //     }
    //   }
    //   return [...map.values()];
    // }
    duplicate(arr, key) {
      const map = new Map()
      return arr.filter((item) => !map.has(item[key] + '') && map.set(item[key] + '', 1))
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-image: url('../../assets/images/bg_comm_png@2x.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #F8F8F8;
  .header {
    padding: 5px 12px;
    .searchContent {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      .searchBtn {
        display: block;
        margin-left: 12px;
        height: 30px;
        line-height: 30px;
        border-radius: 30px;
        font-size: 14px;
        color: #FFFFFF;
        padding: 0 10px;
        background-color: rgba(202, 0, 27, 0.9);
      }
    }
    .searchBox {
      display: flex;
      flex: 1;
      align-items: center;
      height: 30px;
      line-height: 30px;
      border-radius: 30px;
      background-color: rgba(255, 255, 255, 0.9);
      padding: 0 15px;
      .closeIcon {
        width: 15px;
        height: 15px;
      }
      .searchInput {
        flex: 1;
        border: none;
        margin-right: 6px;
        background-color: transparent;
      }
    }
    .header_title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;
      .searchHistory{
        font-size: 16px;
        color: #FFFFFF;
      }
      .clearIcon {
        width: 14px;
        height: 14px;
      }
    }
  }
  .content {
    margin-top: 1px;
    padding: 12px;
    background-color: #F8F8F8;
    border-radius: 12px 12px 0 0;
    .historyList {
      margin: 10px 0;
      span {
        text-align: center;
        min-width: 30px;
        display: inline-block;
        height: 33px;
        line-height: 33px;
        border-radius: 33px;
        padding: 0 10px;
        font-size: 12px;
        color: #666666;
        background-color: #FFFFFF;
        border: 1px solid #CCCCCC;
        margin: 0 10px 10px 0;
      }
    }
    .popularSearch {
      background-color: #FFFFFF;
      border-radius: 10px;
      padding: 15px;
      .popularTitle {
        span {
          font-size: 15px;
          color: #333333;
          font-weight: bold;
          margin: 0 5px;
        }
        img {
          width: 10px;
        }
      }
      .popularList {
        margin-top: 5px;
        span {
          display: inline-block;
          height: 33px;
          line-height: 33px;
          border-radius: 33px;
          padding: 0 10px;
          font-size: 12px;
          color: #666666;
          background-color: #FFFFFF;
          border: 1px solid #CCCCCC;
          margin: 5px;
        }
      }
    }
  }
  .mescroll {
    position: fixed;
    top: 83px;
    bottom: 0;
    height: auto;
    padding: 15px 12px 0;
    border-radius: 12px 12px 0 0;
    box-sizing: border-box;
    background-color: #F8F8F8;
  }
  .list {
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 10px;
    .item_img {
      position: relative;
      .item_cover {
        display: block;
        width: 100%;
      }
      .playIcon {
        position: absolute;
        right: 12px;
        bottom: 12px;
        width: 30px;
        height: 30px;
      }
    }
    .item_content {
      padding: 5px 8px 8px;
      .item_title {
        height: 38px;
        font-size: 14px;
        color: #333333;
        overflow: hidden;
        text-overflow:ellipsis;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
        word-wrap: break-word;
      }
      .item_time {
        font-size: 12px;
        color: #999999;
        line-height: 24px;
      }
      .item_label {
        display: flex;
        flex-wrap: wrap;
        span {
          display: inline-block;
          height: 20px;
          line-height: 20px;
          border-radius: 2px;
          padding: 0 6px;
          margin-right: 6px;
          margin-bottom: 5px;
          background-color: rgba(255, 73, 98, 0.15);
          font-size: 11px;
          color: #CA001B;
        }
      }
      .item_nums {
        display: flex;
        justify-content: flex-end;
        .nums {
          display: flex;
          align-items: center;
          img {
            width: 12px;
            margin: 0 2px 0 6px;
          }
          span {
            font-size: 11px;
            color: #666666;
          }
        }
      }
    }
  }
}
</style>